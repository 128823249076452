import React from 'react';
import { decomposeColor, recomposeColor, hslToRgb } from '@material-ui/core/styles/colorManipulator';

export const useCombinedRefs = (...refs) => {
  const targetRef = React.useRef()

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef;
}

export const colorToDecomposedRGB = (color) => {
  const dColor = decomposeColor(color);
  const rgb = dColor.type === 'hsl' ? decomposeColor(hslToRgb(dColor)).values : dColor.values;
  return { rgb, type: dColor.type };
}

//Amount must be between 0 and 1
export const getColorFade = (start, end, amount) => {
  const startColor = colorToDecomposedRGB(start);
  const endColor = colorToDecomposedRGB(end);
  let mixed = [];
  
  for (var i = 0; i < 3; i++) {
    mixed[i] = startColor.rgb[i] + amount*(endColor.rgb[i] - startColor.rgb[i]);
  }
  
  return recomposeColor({ type: startColor.type, values: mixed});
}

export const timestampMs = (timestamp) => {
	if(isNaN(timestamp)) throw new Error("The variable passed to function 'timestampMs' is not a number")

	if(timestamp.toString().length>13){
		timestamp = timestamp.toString().substr(0, 13)
	}else{
		let len = 13 - (timestamp.toString().length)
		while(len>0){
			timestamp += '0';
			len--;
		}
	}
	return parseInt(timestamp);
}

export const dateToZulu = (timestamp, UTC) => {
	if(!timestamp || timestamp.toString().toLowerCase()==='n/a' || timestamp==="") return timestamp;

	if(isNaN(timestamp)){
		//console.log("NaN DateToZulu", timestamp)

		if(timestamp.toString().includes('UTC')){
			timestamp = timestamp.toString().substr(0, 19)
			//console.log("TIME SUBSTR", timestamp)
			let date = new Date(timestamp).toISOString()
		  return date.substr(0, date.length-5)+'Z';
		}

		if(timestamp.toString().includes('Z') && timestamp.toString().length>20){
			return timestamp.substr(0, timestamp.toString().length-8)+'Z'
		}
		if(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(timestamp.toString())){
			let date = new Date(timestamp).toISOString()
		  return date.substr(0, date.length-5)+'Z';
		}
		return timestamp;
	}
	
	//console.log("DateToZulu", timestamp)

	timestamp = timestampMs(timestamp);

	if(UTC) timestamp += " UTC";
	
	//console.log('TIME', timestamp)

	let date = new Date(timestamp).toISOString()
	return date.substr(0, date.length-5)+'Z';
}

export const timeToString = (time_in_sec, longer_string=false) => {
	//console.log(time_in_sec);
	if(time_in_sec<60){
    return time_in_sec + 's';
  } else if(time_in_sec > 59 && time_in_sec < 3600){
    return (time_in_sec/60).toFixed(2) + (longer_string ? ' mins' : 'm');
  } else if(time_in_sec>3599 && time_in_sec<86400){
    return (time_in_sec/3600).toFixed(2) + (longer_string ? ' hours' : 'h');
  } else if(time_in_sec>86399 && time_in_sec<604800){
		 return (time_in_sec/86400).toFixed(2) + (longer_string ? ' days' : 'd');
	} else if(time_in_sec>604799){
		return (time_in_sec/604800).toFixed(2) + (longer_string ? ' week' : 'w');
	} else return time_in_sec;
}

export const stringToSec = (time_string) => {
//	console.log(time_string);
	if(!time_string) return null;
	let letter = time_string.substr(-1);
	let time = time_string.substr(0, time_string.length-1);
	//console.log(time);
	switch(letter){
		case 's': return time;
		case 'm': return time * 60;
		case 'h': return time * 3600;
		case 'd': return time * 86400;
		case 'w': return time * 86400 * 7;
		default: return null;
	}
}


export const timeToSec = (duration) => {
	const splitted = duration.split(':')
	let out=0;
	const m = [1, 60, 3600, 86400];
	const start = splitted.length-1;
  for(let i=start;i>=0;i--){
	  out += (parseInt(splitted[i])*m[start-i])
  }
	return out;
}

export const secToTime = (sec) => {   
  	let out='';
    let d = Math.floor(sec/86400)
    let h = Math.floor((sec%86400)/3600)
    let m = Math.floor((sec%3600)/60)
    let s = Math.floor(sec%60)
    if(d<10) d='0'+d
    if(h<10) h='0'+h
    if(m<10) m='0'+m
    if(s<10) s='0'+s
    if(parseInt(d)>0){
    	out = d+':'+h+':'+m+':'+s
    }else{
    	out = h+':'+m+':'+s
    }
    //console.log(out)
  	return out;
}


export const  formatData = (val, zero=true) => {
		if(val===0){
			if(zero){
				return val
			}else{
				return 
			}
		}

		return val>0 ? convertByte(val) : val
		//return val>0 ? convertByte(val, 'MB') : val
	}

export const fixNumber = (x, digits=2) => {
  return Number.parseFloat(x).toFixed(digits);
}

export const formatBigNumber = (val) => {
	if(typeof val !== 'number') return val

	if(val<=0) return 0

	if(val<1000){
		return fixNumber(val)
	}
	let v = val
	let c = ['K', 'M', 'B'];
	let i=-1
	while(v>=1000){
		v = v/1000;
		i++;
	}
	return fixNumber(v) + c[i];
}

const isInt = (n) => {
   return n % 1 === 0;
}

export const convertByte = (val, start='KB') => {
	if(typeof val !== 'number') return val

	if(val<=0) return 0

	let v = val
	let c = ['KB', 'MB', 'GB', 'TB'];
	switch(start){
		case 'KB': c = ['KB', 'MB', 'GB', 'TB'];
			break;
		case 'MB': c = ['MB', 'GB', 'TB'];
			break;
		case 'GB': c = ['GB', 'TB'];
			break;
		default: throw new Error("Available starting point are: ['KB', 'MB', 'GB', 'TB']")
	}

	if(v<1000){
	  switch(start){
   		case 'KB': return v+'B';
    	case 'MB': return v+'KB';
    	case 'GB': return v+'MB';
	  } 
		return fixNumber(v)
	}

	let i=-1
	while(v>=1000){
		v = v/1000;
		i++;
	}
	
	if(!isInt(v)){
		v = fixNumber(v);
	}

	return fixNumber(v) + c[i];
}

export const renderBigNum = (val) => {
	const rendered = formatBigNumber(val);

	return <p title={val}>{rendered}</p>
}

export const renderByte = (val, start='KB') => {
	const rendered = convertByte(val, start);

	return <p title={val}>{rendered}</p>
}

export const renderPercentage = (val) => (val>=0) ? parseFloat(val).toFixed(2)+'%' : val


export const saveFile = async (blob, suggestedName) => {
  // Feature detection. The API needs to be supported
  // and the app not run in an iframe.
  const supportsFileSystemAccess =
    'showSaveFilePicker' in window &&
    (() => {
      try {
        return window.self === window.top;
      } catch {
        return false;
      }
    })();
  // If the File System Access API is supported…
  if (supportsFileSystemAccess) {
    try {
      // Show the file save dialog.
      const handle = await window.showSaveFilePicker({
        suggestedName,
      });
      // Write the blob to the file.
      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
      return;
    } catch (err) {
		console.log("error in saving file",err);
		
      // Fail silently if the user has simply canceled the dialog.
      if (err.name === 'AbortError') {
        console.log(err.name, err.message);
        return;
      }
    }
  }
  // Fallback if the File System Access API is not supported…
  // Create the blob URL.
  const blobURL = URL.createObjectURL(blob);
  // Create the `<a download>` element and append it invisibly.
  const a = document.createElement('a');
  a.href = blobURL;
  a.download = suggestedName;
  a.style.display = 'none';
  document.body.append(a);
  // Programmatically click the element.
  a.click();
  // Revoke the blob URL and remove the element.
  setTimeout(() => {
    URL.revokeObjectURL(blobURL);
    a.remove();
  }, 1000);
};
