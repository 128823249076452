import React, { Suspense, lazy } from 'react';
import { Router, Route, Redirect } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import Header from 'components/header';
import Footer from 'components/footer';
import Loader from 'components/loader';

import history from 'utils/history.js';

//Lazy load for optimization
const ShipsOverview = lazy(() => import('components/ships-overview'));
const ShipsOverviewIOT = lazy(() => import('components/iot/ships-overview'));
const ShipsOverviewData = lazy(() => import('components/data/ships-overview'));
const Realtime = lazy(() => import('components/ship-tracking'));
const DashboardEditor = lazy(() => import('components/tabbed-dashboard-editor'));
const CircleManager = lazy(() => import('components/circle-manager'));
const TimeMachine = lazy(() => import('components/time-machine'));
const Voyages = lazy(() => import('components/voyages'));
const Grapher = lazy(() => import('components/grapher'));
const Login = lazy(() => import('components/login'));
const PasswordForgot = lazy(() => import('components/password-forgot'));
const PasswordReset = lazy(() => import('components/password-reset'));
const PasswordTokenExpired = lazy(() => import('components/password-token-expired'));
const Logout = lazy(() => import('components/logout'));
const Autologin = lazy(() => import('components/autologin'));
const Subsystems = lazy(() => import('components/subsystems'));
const ConnectivityStatus = lazy(() => import('components/connectivity-status'));
const SystemFleetMonitor = lazy(() => import('components/iot/fleet_monitor'));
const Users = lazy(() => import('components/users'));
const Version = lazy(() => import('components/version'));
const CollectionConfig = lazy(() => import('components/collection-configuration'));
const LiveAlerts = lazy(() => import('components/live-alerts'));
const HistoryAlerts = lazy(() => import('components/history-alerts'));
//const Quality = lazy(() => import('components/quality')); 
//const DataQuality = lazy(() => import('components/data-quality')); 
const Quality = lazy(() => import('components/data/quality')); 
const DataMapping = lazy(() => import('components/data/mapping')); 
const Grafana = lazy(() => import('components/grafana')); 
const DataFleet = lazy(() => import('components/data-fleet'));
const DataVessel = lazy(() => import('components/data/vessel'));
const EdgeStatus = lazy(() => import('components/iot/edge_status'));
const Reports = lazy(() => import('components/analytics/reports'));


const useStyles = makeStyles(theme => ({
  body: {
    color: theme.palette.text.main,
    /*backgroundColor: theme.palette.primary.light,
    '& *:fullscreen': {
      backgroundColor: theme.palette.primary.light,
    },*/
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    overflowX: 'hidden !important',
    flex: '1 1 auto',
    height: 0,
/*		paddingRight: '20px',*/
  },
}));


const PrivateRoute = ({ render, isAuth, ...props}) => (
  isAuth
    ? <Route {...props} render={render} />
    : <Route {...props} render={props => <Redirect to={{ pathname: '/', state: { from: props.location } }} />} />
);

const Wrapper = _props => {
  const { imo, Component, classes, payload, askPayload, auth, user, ...props} = _props;
	
	React.useEffect(() => {
/*
console.log("PAYLOAD", payload)
		if(!payload.pending && !payload.payload) {
    	askPayload(auth, imo);
  	}
*/

  	/*if (!props.imo && imo) {
    	props.setImo(imo);
  	}*/

	}, [])

  if (!payload.payload || !user.user) {
    return <Loader fullScreen={true}/>
  }

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <Header {...props} imo={imo} />
        <div className={classes.main} id="mainContainer">
          <Component {...props} imo={imo} />
        </div>
        <Footer {...props} imo={imo} />
      </div>
    </div>
  );
}

const render = (Component, parentProps, classes) => (props) => (
  <Wrapper
    {...props}
	user={parentProps.user}
    Component={Component}
    classes={classes}
    payload={parentProps.payload}
    askPayload={parentProps.askPayload}
    auth={parentProps.auth}
    imo={parentProps.imo}
		id={parentProps.id}
    setImo={parentProps.setImo}
		fullMap={parentProps.fullMap ?? false}
  />
);

const WrapperNoImo = _props => {
  const { Component, classes, ...props} = _props;

  return (
    <div className={classes.container}>
      <Header {...props} />
      <div className={classes.main} id="mainContainer">
        <Component {...props} />
      </div>
      <Footer {...props} />
    </div>
  );

}

const renderNoImo = (Component, parentProps, classes) => (props) => (
  <WrapperNoImo
    {...props}
    Component={Component}
    classes={classes}
    payload={parentProps.payload}
    askPayload={parentProps.askPayload}
		fullMap={parentProps.fullMap ?? false}
  />
);

const AppView = props => {
  const isAuth = props.auth && props.auth.authenticated ? true : false;
  const classes = useStyles();
	const {initFrame, setFleetFrame, setFleet, setImo, fleet, ships, fleets, payload, askPayload, auth, imo, user, askUser} = props;

	const [expired, setExpired] = React.useState(false);
	const [message, setMessage] = React.useState('');

	const [permissions, setPermissions] = React.useState()

	const canBeLoggedOut = () => {
		//console.log('Fleets', fleets)
		console.log('Fleets len', fleets && fleets.length)
		//console.log('Ships', ships)
		console.log('Ships len', ships && ships.length)
		return fleets==undefined || fleets && fleets.length==0 || ships==undefined || ships && ships.length==0;
	}

	let timer=0
	React.useEffect(() => {
		//log out after 5 minute
		timer = setTimeout(() => {
			console.log("expired true")
			setExpired(true)
		}, 300000)  //5min = 300000

		if(!fleets){
			props.getFleets(props.auth)
		}
		if(!ships){
			props.getShips(props.auth)
		}

/*
		if(user.user?.services || user.services){
 			askUser(auth, imo)
		}
*/

	}, [])

	const logout = () => history.push('/logout');

	React.useEffect(() => {
		if(!canBeLoggedOut()){
			setExpired(false)
		}else{
			if(expired && isAuth){
				setMessage(msg => 'Time out. Please contact your admin to resolve this issue.');
				logout();
			}
		}
	}, [expired])

	React.useEffect(() => {
		if(fleets && ships){
			setExpired(false)
			clearTimeout(timer)
      timer=null
		}
	}, [fleets, ships])

	React.useEffect(() => {
		if(user && user.user?.services){
			const perms = user.user.services.permissions;
			const services = {
				alerts: ['portal_alerts_disconnection_alerts-read','portal_alerts_collecion_alerts-read'],
				users: ['portal_users-read'],
				system_overview: ['portal_system_overview-read'], 
				system_fleet_monitor: ['portal_system_fleet_monitor-read'], 
				system_vessel: ['portal_system_vessel-read'], 
				system_collections: ['portal_system_collections-read'], 
				system_edge_status: ['portal_system_edge_status-read'],
				data_overview: ['portal_data_overview-read'],
				data_fleet_monitor: ['portal_data_fleet_monitor-read'],
				data_vessel: ['portal_data_vessel-read'],
				data_quality: ['portal_data_quality-read'],
				data_mapping: ['portal_data_mapping-read'],
				operations_vessel : ['portal_operations_vessel-read'],
				operations_live : ['portal_operations_live-read'],
				operations_live_update : ['portal_operations_live-update'],
				operations_time_machine : ['portal_operations_time_machine-read'],
				operations_geofence : ['portal_operations_geofence-read'],
				analytics: ['portal_analytics-read'],
			}
			Object.keys(services).forEach(perm_name => {
				let res=false;
				services[perm_name].forEach(perm => {
					res += perms.includes(perm);
					//setPermissions(ps => ({perm_name: res, ...ps}))
				});
				//console.log(perm_name, res);
				setPermissions(perm => ({perm_name: res, ...perm}));
			});
		}
	}, [user])

	React.useEffect(() => {
		//console.log("selectFleet fleet id", fleet)
		//console.log("selectFleet fleets", fleets)
		if(fleet && fleets){
			clearTimeout(timer)
			timer=null
			console.log('Fleet changed', fleet)
			const fleet_found = fleets.find(f => { if(f.id==fleet) return f });
			console.log("selectFleet fleet", fleet_found)
			if(fleet_found){
				if(!validImo){
					const imo = fleet_found.ships[0];
					console.log('SHIP IMO', imo)
					setImo(imo)
				}

				//if(!fleet_frame){
					setFleetFrame(auth, fleet);
				//}
			}
		}
	}, [fleet, fleets])

	const fleet_found = fleets ? fleets.find(_fleet => { if(_fleet.id==fleet) return _fleet }) : null;
	const validImo = fleet_found ? fleet_found.ships.find(_ship => (_ship==imo)) : null;

	const getFleetFromShip = (imo) => {
		if(fleets){
			const found = fleets.find(fleet => (fleet.ships.find(ship => ship==imo)) ? fleet : false);
			return found ? found.id : false;
		}
		return false
	}

	React.useEffect(() => {
		console.log("PAYLOAD", payload)
		//console.log("valid imo", fleet_found)
		//console.log("valid imo", imo)
		//console.log("valid imo", validImo)
		/*if(!payload.pending && !payload.payload && validImo) {
   		askPayload(auth, imo);
 		}*/

		if(ships){
			clearTimeout(timer)
			timer=null
			const ship = ships.find(ship => { if(ship.imo===imo) return ship });
			if(ship){
				console.log("imo changed", ship)
				console.log("current fleet", fleet)
				console.log("current fleet", fleet_found)
				const fleet_id = getFleetFromShip(ship.imo)
				if(fleet_id && fleet_id!==fleet_found){
					setFleet(fleet_id)
				}

				initFrame()
 				askUser(auth, imo)
		   	askPayload(auth, imo);
			}
		}
	}, [imo, ships])


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router history={history}>
        <Suspense fallback={<Loader fullScreen={true}/>}>
          <PrivateRoute isAuth={isAuth} path="/iot/overview" render={render(ShipsOverviewIOT, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/iot/fleetmonitor" render={render(SystemFleetMonitor, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/iot/vessels" render={render(Grafana, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/iot/collections" render={render(CollectionConfig, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/iot/edgestatus" render={render(EdgeStatus, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/data/overview" render={render(ShipsOverviewData, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/data/vessels" render={render(DataVessel, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/data/fleetmonitor" render={render(DataFleet, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/data/quality" render={render(Quality, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/data/mapping" render={render(DataMapping, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/operations/live" render={render(ShipsOverview, {...props, fullMap: true}, classes)} />
          <PrivateRoute isAuth={isAuth} path="/operations/vessels" render={render(Realtime, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/operations/edit-vessels" render={render(DashboardEditor, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/operations/circles" render={render(CircleManager, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/operations/timemachine" render={render(TimeMachine, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/analytics/reports" render={render(Reports, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/users" render={render(Users, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/alerts" render={render(LiveAlerts, props, classes)} />
{/*
          {(permissions && permissions.system_overview) && <PrivateRoute isAuth={isAuth} path="/iot/overview" render={render(ShipsOverviewIOT, props, classes)} />}
          {permissions && permissions.system_fleet_monitor && <PrivateRoute isAuth={isAuth} path="/iot/fleetmonitor" render={render(SystemFleetMonitor, props, classes)} />}
          {permissions && permissions.system_vessel && <PrivateRoute isAuth={isAuth} path="/iot/vessels" render={render(Grafana, props, classes)} />}
          {permissions && permissions.system_collections && <PrivateRoute isAuth={isAuth} path="/iot/collections" render={render(CollectionConfig, props, classes)} />}
          {permissions && permissions.system_edge_status && <PrivateRoute isAuth={isAuth} path="/iot/edgestatus" render={render(EdgeStatus, props, classes)} />}
          {permissions && permissions.data_overview && <PrivateRoute isAuth={isAuth} path="/data/overview" render={render(ShipsOverviewData, props, classes)} />}
          {permissions && permissions.data_vessel && <PrivateRoute isAuth={isAuth} path="/data/vessels" render={render(DataVessel, props, classes)} />}
          {permissions && permissions.data_fleet_monitor && <PrivateRoute isAuth={isAuth} path="/data/fleetmonitor" render={render(DataFleet, props, classes)} />}
          {permissions && permissions.data_quality && <PrivateRoute isAuth={isAuth} path="/data/quality" render={render(Quality, props, classes)} />}
          {permissions && permissions.operations_mapping && <PrivateRoute isAuth={isAuth} path="/data/mapping" render={render(DataMapping, props, classes)} />}
          {permissions && permissions.operations_live && <PrivateRoute isAuth={isAuth} path="/operations/live" render={render(ShipsOverview, {...props, fullMap: true}, classes)} />}
          {permissions && permissions.operations_vessel && <PrivateRoute isAuth={isAuth} path="/operations/vessels" render={render(Realtime, props, classes)} />}
          {permissions && permissions.operations_vessel_update && <PrivateRoute isAuth={isAuth} path="/operations/edit-vessels" render={render(DashboardEditor, props, classes)} />}
          {permissions && permissions.operations_geofence && <PrivateRoute isAuth={isAuth} path="/operations/circles" render={render(CircleManager, props, classes)} />}
          {permissions && permissions.operations_time_machine && <PrivateRoute isAuth={isAuth} path="/operations/timemachine" render={render(TimeMachine, props, classes)} />}
          {permissions && permissions.analytics && <PrivateRoute isAuth={isAuth} path="/analytics/reports" render={render(Reports, props, classes)} />}
          {permissions && permissions.users && <PrivateRoute isAuth={isAuth} path="/users" render={render(Users, props, classes)} />}
          {permissions && permissions.alerts && <PrivateRoute isAuth={isAuth} path="/alerts" render={render(LiveAlerts, props, classes)} />}
*/}

          {/* <PrivateRoute exact isAuth={isAuth} path="/alerts-history" render={render(HistoryAlerts, props, classes)} /> */}
{/*
          <PrivateRoute isAuth={isAuth} path="/version" render={renderNoImo(Version, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/collections" render={render(CollectionConfig, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/alerts" render={render(LiveAlerts, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/alerts-history/:type?/:id?" render={render(HistoryAlerts, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/realtime" render={render(Realtime, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/edit" render={render(DashboardEditor, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/circles" render={render(CircleManager, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/timemachine" render={render(TimeMachine, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/voyages" render={render(Voyages, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/grapher" render={render(Grapher, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/subsystems" render={render(Subsystems, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/dataquality" render={render(DataQuality, props, classes)} />
          <PrivateRoute isAuth={isAuth} path="/:imo/grafana" render={render(Grafana, props, classes)} />
*/}
          <Route path="/login/:token/:imo?" component={Autologin} />
          <Route path="/logout" render={(props) => <Logout message={message}/>} />
          {/*<Route path="/logout" component={Logout} />*/}
          <Route exact path="/" component={Login} />
          <Route isAuth={isAuth} exact path="/password/forgot" component={PasswordForgot} />
          <Route isAuth={isAuth} path="/password/reset/:token?/:email?" component={PasswordReset} />
          <Route isAuth={isAuth} path="/password/token-expired" component={PasswordTokenExpired} />
        </Suspense>
      </Router>
    </MuiPickersUtilsProvider>
  );
}

export default AppView;
